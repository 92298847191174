<template>
  <div
    class="videoList-box"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--操作栏-->
    <refreshbtn @refesh="refesh">
      <template slot="search-btns">
        <el-button type="success" size="small" @click="addFromMethod" v-has="'/api/auth/admin/menu/create'">添加</el-button>
      </template>
    </refreshbtn>
    <!--数据表格-->
    <el-table
      ref="theTable"
      :data="tableData"
      row-key="id"
      border
      :key="1"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      :default-expand-all="false"
      :tree-props="{ children: 'child'}"
    >
      <!-- <el-table-column
        prop="id"
        label="Id"
        align="center"
        width="140"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="title"
        label="标题"
        :show-overflow-tooltip="true"
        width="250"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.title || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tag_count" label="图标" width="200" align="center">
        <template slot-scope="scope">
          <i :class="scope.row.icon"></i>
        </template>
      </el-table-column>
      <el-table-column label="规则" align="center" prop="route">
      </el-table-column>

      <el-table-column label="状态" align="center" prop="enable" width="80">
        <template slot-scope="scope">
          <!--          <el-switch @change="updateData(scope.row)"  v-model="scope.row.enable"></el-switch>-->
          {{ scope.row.enable == true ? "正常" : "失效" }}
        </template>
      </el-table-column>
      <el-table-column prop="menu_type" label="菜单" align="center" width="130">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.menu_type"
            @change="updateData(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="menu"
            inactive-value="auth"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="sort_order"
        label="排序"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-has="'/api/auth/admin/menu/update'" @click="editForm(scope.row)">
            编辑
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="destroyCate(scope.row.id)"
            v-has="'/api/auth/admin/menu/destroy'"
            v-if="!scope.row.system_menu"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--封面图、视频弹窗组件-->
    <!-- 分页组件 -->
    <addForm ref="addForm"></addForm>
  </div>
</template>

<script>
import addForm from "./components/addMenu.vue";
import API from "@/api";
export default {
  data() {
    return {
      // 请求列表查询
      params: {
        page: 1,
        size: 10,
      },
      loading: false,
      // 列表数据
      tableData: [],
      total: "",
      dialogVisible: false,
      updataSta: false,
      isRefesh:true,
    };
  },
  components: {
    addForm,
  },
  activated() {
    let listRule = '/api/auth/admin/menu/list'
    let obj = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('btnPower'))));
    if(obj[listRule]){
      this.isRefesh = true
      this.getList()
    }else{
      this.isRefesh = false 
    }
  },
  methods: {
    refesh(){
      this.isRefesh?this.getList():''
    },
    async updateData(row) {
      await API.updateMenu(row);
    },
    destroyCate(id) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.destroyMenu({ id }).then((res) => {
            if(!res.code){
              this.$message.success("删除成功");
              this.getList();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
    },
    // handleImageUrl(src) {
    //   if (src[0] === "/") {
    //     src = src.substr(1, src.length);
    //   }
    //   return src;
    // },
    addFromMethod() {
      this.$refs.addForm.addFormData();
    },
    editForm(data) {
      this.$refs.addForm.editData(this._.cloneDeep(data));
    },
    // 获取视频列表
    getList() {
      this.loading = true
      API.getMenuList(this.params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.tableData.forEach((item) => {
              // console.log(JSON.stringify(item))
              const timestamp = item.login_time * 1000;
              const date = new Date(timestamp);
              // item.hasChildren = !item.child.length
              item.login_time = date.toLocaleString();
            });
            this.$forceUpdate()
            this.loading = false
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            this.loading = false
            return;
          } else {
            this.tableData = [];
            this.loading = false
          }
        })
        .catch(() => {
         //this.tableData = [];
          this.loading = false;
        });
    },
    // 分页条数改变回调
    changeSize(size) {
      this.params.pageSize = size;
      this.getVideoList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.pageNumber = number;
      this.getVideoList();
    },

    // 编辑回调监听
    handlerVideoList() {
      this.getVideoList();
    },
    handlerPreviewList() {
      this.getVideoList();
    },
    // 查看媒体
    checkMedia(url, type) {
      this.$refs.mediaPopup.open(url, type);
    },
    // 编辑视频
    edit(row) {
      this.$refs.editVideo.open(row);
    },
    // 翻页时的勾选回显
    restoreCheckedState() {
      /**
       * ①获取到当前数据表格中已经被选中的数据
       */
      const indexs = this.tableData
        .map((item) => {
          let flag = false;
          this.videoIds.forEach((value) => {
            value.id === item.id ? (flag = true) : "";
          });
          if (flag) {
            return item;
          }
        })
        .filter((item) => item);
      /**
       * ②回显当前数据表格中已经被勾选过的数据
       */
      this.$nextTick(() => {
        indexs.forEach((rows) => {
          this.$refs.multipleTable.toggleRowSelection(rows);
        });
      });
    },

    // 关闭窗口
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.picturePreview {
  max-width: 300px;
  display: flex;
}
.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
}
.bottom {
  margin-top: 20px;
  .btn {
    display: inline-block;
    margin-left: 20px;
  }
  .page {
    display: inline-block;
    float: right;
  }
}
.el-pagination {
  margin-top: 0 !important;
}
.dialog-footer {
  left: 0;
}
.tagBox {
  display: inline-block;
  margin-right: 5px;
}
// el-table表格对齐
::v-deep .el-table__row:not([class*="el-table__row--level-0"]) {
    td:first-child {
      padding-left: 30px !important;
      box-sizing: border-box;
    }
    td{
      color: #909399;
    }
}
</style>
